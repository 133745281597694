/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, title, type, image, publishedDate, updatedDate, url }) {
  const { site, logo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            rssTitle
            siteUrl
            description
            author
          }
        }
        logo: file(relativePath: { eq: "logo-og-image.png" }) {
          childImageSharp {
            fixed(width: 1200, height: 1200, quality: 80) {
              ...GatsbyImageSharpFixed
            },
          }
        }
      }
    `
  )

  const rssTitle = site.siteMetadata.rssTitle
  const domain = site.siteMetadata.siteUrl
  const metaDescription = description || site.siteMetadata.description
  const metaType = type || `website`
  const metaUrl = url || ``
  const metaImage = image || logo.childImageSharp.fixed;

  const ogImageWidth = metaImage.width ? { property: `og:image:width`, content: metaImage.width } : {}
  const ogImageHeight = metaImage.height ? { property: `og:image:height`, content: metaImage.height } : {}

  const articlePublishedDate = type === `article` ? { property: `article:published_time`, content: publishedDate } : {}
  const articleModifiedDate = type === `article` ? { property: `article:modified_time`, content: updatedDate } : {}

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          property: type === `article` ? `article:author` : undefined,
          content: site.siteMetadata.author,
        },
        articlePublishedDate,
        articleModifiedDate,
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: metaType,
        },
        {
          property: `og:url`,
          content: domain + metaUrl,
        },
        {
          property: `og:image`,
          content: domain + metaImage.src,
        },
        {
          property: `og:image:alt`,
          content: title,
        },
        ogImageWidth,
        ogImageHeight,
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="alternate" type="application/rss+xml" title={rssTitle} href={`${domain}/rss.xml`} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
