import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { rhythm } from '../utils/typography'
import { Fonts } from '../utils/layoutUtils'

const SocialIcons = () => {
  const data = useStaticQuery(graphql`
    query SocialIcons {
      site {
        siteMetadata {
          author
          social {
            facebook,
            instagram,
            twitter,
            linkedin,
            github,
            youtube
          }
        }
      }
    }
  `)

  const { social } = data.site.siteMetadata
  return (
    <div
      style={{
        textAlign: `center`,
        fontFamily: Fonts.SANS,
        marginBottom: rhythm(1),
      }}
    >
      <a href={`https://www.youtube.com/channel/${social.youtube}`}>
        YouTube
      </a>
      {`, `}
      <a href={`https://twitter.com/${social.twitter}`}>
        Twitter
      </a>
      {`, `}
      <a href={`https://www.linkedin.com/in/${social.linkedin}`}>
        LinkedIn
      </a>
      {`, `}
      <a href={`https://instagram.com/${social.instagram}`}>
        Instagram
      </a>
      {`, `}
      <a href={`https://www.facebook.com/${social.facebook}`}>
        Facebook
      </a>
    </div>
  )
}

export default SocialIcons
