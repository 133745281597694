import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

import { rhythm } from '../utils/typography'

const Hero = ({ isRootPath }) => {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      hero: file(absolutePath: { regex: "/hero-with-badges.png/" }) {
        childImageSharp {
          fixed(width: 300, height: 300, quality: 100) {
            ...GatsbyImageSharpFixed
          },
        }
      }
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        flexWrap: `wrap`,
        alignItems: 'center',
        marginLeft: `auto`,
        marginRight: `auto`,
        marginTop: 0,
        maxWidth: rhythm(40),
        borderBottom: `2px solid`,
        justifyContent: `center`,
      }}
    >
      <h2
        style={{
          fontSize: `1.4rem`,
          padding: `0 ${rhythm(1)}`,
          margin: 0,
          lineHeight: `1.8rem`,
          fontWeight: `normal`,
          maxWidth: `${rhythm(19)}`,
        }}
      >
        <p>👋 Hey! My name is Wojciech Gawroński, but others call me <em>AWS Maniac</em>.</p>
        <p>My goal is to safely guide you through the cloudy and foggy space of the <Link to="https://aws.amazon.com">AWS</Link> portfolio.</p>
        {isRootPath && (
          <p style={{ fontSize: `1.2rem` }}>
            <Link style={{ fontWeight: 'bold' }} to="/start-here">Start here</Link> if you are here for the first time.
          </p>
        )}
      </h2>
      <Image
        fixed={data.hero.childImageSharp.fixed}
        alt={author}
        style={{
          marginLeft: rhythm(1 / 2),
          marginBottom: 0,
          maxWidth: '550px',
        }}
      />
    </div>
  )
}

export default Hero
