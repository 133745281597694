import React, { useState } from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'

import { Colors } from '../utils/layoutUtils'

const MobileNav = ({ color = 'white' }) => {
  const [isToggledOn, setToggle] = useState(false)
  const toggle = () => setToggle(!isToggledOn)
  return (
    <div className="mobile-nav">
      <button
        // eslint-disable-next-line react/jsx-no-bind
        onClick={toggle}
        aria-label={`${isToggledOn ? 'close menu' : 'open menu'}`}
        css={css`
          z-index: 30;
          top: -5px;
          padding: 10px 12px;
          position: relative;
          background: transparent;
          border: none;
          :hover:not(.touch),
          :focus {
            background: transparent;
            border: none;
            outline: none;
          }
          cursor: pointer;
        `}
      >
        <div
          css={css`
            width: 24px;
            height: 2px;
            background: ${color};
            position: absolute;
            left: 0;
            ${isToggledOn ? 'background: transparent' : `background: ${color}`};
            transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1);
            ::before {
              content: '';
              top: -8px;
              width: 24px;
              height: 2px;
              background: ${isToggledOn ? 'white' : `${color}`};
              position: absolute;
              left: 0;
              ${isToggledOn
      ? 'transform: rotate(45deg); top: 0; '
      : 'transform: rotate(0)'};
              transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1);
            }
            ::after {
              top: 8px;
              content: '';
              width: 24px;
              height: 2px;
              background: ${isToggledOn ? 'white' : `${color}`};
              position: absolute;
              left: 0;
              ${isToggledOn
      ? 'transform: rotate(-45deg); top: 0;'
      : 'transform: rotate(0)'};
              transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1);
            }
          `}
        />
      </button>
      {isToggledOn && (
        <div
          css={css`
            position: absolute;
            z-index: 20;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${Colors.MAIN};
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-evenly;
              a {
                color: white;
                font-size: 22px;
                margin: 10px 0;
                padding: 10px;
                :hover {
                  background: rgba(0, 0, 0, 0.2);
                }
                box-shadow: none;
              }
              .active {
                background: rgba(0, 0, 0, 0.2);
              }
            `}
          >
            <Link to="/exams/" css={css`font-weight: bold;`}>
              AWS Exam Guides
            </Link>
            <Link to="/newsletter/" css={css`font-weight: bold;`}>
              Newsletter
            </Link>
            <Link to="/blog/">
              Blog
            </Link>
            <Link to="/contact/">
              Contact
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default MobileNav
