import React from 'react'
import { Link } from 'gatsby'
import CookieConsent from 'react-cookie-consent'
import { Global, css } from '@emotion/core'

import { rhythm } from '../utils/typography'
import { PageWidths, Colors, Fonts } from '../utils/layoutUtils'
import logo from '../content/assets/logo.png'

import Hero from './hero'
import SocialIcons from './socialIcons'
import MobileNav from './mobileNav'

export const globalStyles = css`
  /* inter-regular - latin-ext_latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/inter-v2-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/inter-v2-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/inter-v2-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/inter-v2-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
        url('../fonts/inter-v2-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/inter-v2-latin-ext_latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
  }

  /* Colors */
  /* main dark: #2b3d4f */
  /* additional dark: #d53b30 */

  body {
    background-color: ${Colors.BACKGROUND};
    color: ${Colors.TEXT};
  }

  p {
    margin-top: 0;
    margin-bottom: 1.55rem;
  }

  ul, ol {
    margin-left: 30px;
  }

  li {
    margin-top: 0;
    margin-bottom: 0;
  }

  li > ul {
    margin-top: 0;
    margin-bottom: 0
  }

  li > ol {
    margin-top: 0;
    margin-bottom: 0
  }

  a {
    color: ${Colors.MAIN};
  }

  h1, h2, h3, h4, h5, h6 {
    text-align: left;
    margin-top: 1.55rem;
    margin-bottom: calc(1.55rem/2)
  }

  hr {
    background: hsla(0,0%,0%,0.2);
  }

  /**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   */

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
  }

  /**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */

  button,
  input { /* 1 */
    overflow: visible;
  }

  /**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */

  button,
  select { /* 1 */
    text-transform: none;
  }

  /**
  * Correct the inability to style clickable types in iOS and Safari.
  */

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  .gatsby-highlight {
    margin-left: -1.275rem;
    margin-right: -1.275rem;
    margin-bottom: 1.7rem;
  }

  code[class*="language-"] {
    font-size: 0.9em;
  }

  .live-stream-logos a {
    text-decoration: none currentcolor solid;
    border-bottom: 0;
    box-shadow: none;
  }

  .live-stream-logos img {
    margin-bottom: 0;
  }

  .youtube-player {
    display: block;

    width: 580px;
    height: 327px;

    @media (max-width: 425px) {
      width: 270px;
      height: 152px;
    }

    margin: 0 auto 2rem auto;
    border: 0;
  }

  .timeline {
    width: 99vw;
    margin-left: calc(50% - 50vw);
  }
`

const maxSM = 767
export const bpMaxSM = `@media (max-width: ${maxSM}px)`
export const bpJustLogo = `@media (max-width: 505px)`

const NavLink = props => (
  <Link
    css={css`
      color: ${Colors.TEXT};
      font-weight: bold;
      box-shadow: none;
      padding: 8px 10px;
      background: transparent;
      '& + &': { margin-left: 10 };
      ${bpMaxSM} {
        display: none;
      };
      :hover {
        box-shadow: 0 1px 0 0 currentColor;
      }
    `}
    {...props}
  />
)

const Header = ({ title }) => (
  <h3
    css={css`
      margin-top: 0;
      margin-bottom: 0;
    `}
  >
    <Link
      style={{
        boxShadow: `none`,
        textDecoration: `none`,
        color: `inherit`,
        display: `flex`,
        flexWrap: `wrap`,
        alignItems: `center`,
      }}
      to={`/`}
    >
      <img
        style={{
          height: '10rem',
          marginRight: '15px',
          marginBottom: '0',
        }}
        src={logo}
        alt="logo"
      />
    </Link>
  </h3>
)

const TopMenu = () => (
  <div
    css={css`
      font-size: 16px;
      line-height: 1.25;
      display: flex;
      align-items: center;
      .mobile-nav {
        display: none;
        visibility: hidden;
        ${bpMaxSM} {
          display: block;
          visibility: visible;
        }
      }
    `}
  >
    <MobileNav color="black" />
    <NavLink to="/exams/" css={css`color: ${Colors.ORANGE}`}>
      AWS Exam Guides
    </NavLink>
    <NavLink to="/newsletter/" css={css`color: ${Colors.MAIN}`}>
      Newsletter
    </NavLink>
    <NavLink to="/blog/">
      Blog
    </NavLink>
    <NavLink to="/contact/">
      Contact
    </NavLink>
  </div>
)

class Layout extends React.Component {
  static defaultProps = {
    frontmatter: {},
    pageWidth: PageWidths.NORMAL,
  }

  render() {
    const { location, title, children, frontmatter: { hero }, pageWidth } = this.props
    // eslint-disable-next-line no-undef
    const rootPath = `${__PATH_PREFIX__}/`
    const isRootPath = location.pathname === rootPath

    const pageSizeStyles = {
      marginLeft: `auto`,
      marginRight: `auto`,
      maxWidth: pageWidth,
      padding: `${rhythm(1.5)} ${rhythm(0.75)}`,
    }

    return (
      <div>
        <Global styles={globalStyles} />
        <header
          style={{
            ...pageSizeStyles,
            fontFamily: Fonts.SANS,
            maxWidth: PageWidths.WIDE,
            paddingBottom: 0,
            marginBottom: `${rhythm(1)}`,
            display: `flex`,
            flexWrap: `wrap`,
            alignItems: `center`,
            justifyContent: `space-between`,
          }}
        >
          <Header title={title} />
          <TopMenu />
        </header>
        { hero && <Hero isRootPath={isRootPath} /> }
        <main
          style={pageSizeStyles}
        >
          {children}
          <hr />
          <SocialIcons />
        </main>
        <footer
          style={{
            ...pageSizeStyles,
            fontSize: `0.7rem`,
            fontFamily: Fonts.SANS,
            padding: `${rhythm(0.5)} ${rhythm(3 / 4)}`,
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: 'space-between',
          }}
        >
          <div
            css={css`
              @media only screen and (max-width: 600px) {
                text-align: center;
              }
            `}
          >
            awsmaniac.com © {new Date().getFullYear()}, built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a> and template from
            {` `}
            <a href="https://github.com/kjendrzyca">@kjendrzyca</a>
          </div>
          <div
            css={css`
              @media only screen and (max-width: 600px) {
                margin: 1rem auto 0 auto;
              }
            `}
          >
            <Link to="/portfolio">Portfolio</Link>
            {` · `}
            <Link to="/privacy-policy">Privacy Policy</Link>
            {` · `}
            <Link to="/contact">Contact</Link>
          </div>
        </footer>
        <CookieConsent
          location="bottom"
          buttonText="ACCEPT"
          style={{ fontSize: '12px', textAlign: 'center', justifyContent: 'center' }}
        >
          This page uses cookies 🍪. <Link to="/privacy-policy">Here</Link> you can read why.
        </CookieConsent>
      </div>
    )
  }
}

export default Layout
